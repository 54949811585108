#programs-main {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#programs-heading-section * {
    z-index: 300;
}

#programs-heading-section {
    padding-top: 50px;
    height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('./images/ajay0004_a_bunch_of_hackers_jumping_in_the_air_in_victory_after_f49f6105-b9f7-4e79-b01e-7e47f1d76ecf.jpg');

    background-size: cover;
    background-position-y: 50%;
    background-attachment: fixed;
}

#programs-heading-section .heading {
    margin: 0;
    z-index: 300;
    font-size: 60px;
}

#programs-heading-section p {
    z-index: 300;
    text-align: center;
}

/* CURRICULUMS SECTION */

.half {
    flex: 1;

}

.image-holder {
    display: flex;
    justify-content: center;
    align-items: center;
}


.curriculum-image {
    background-size: cover;
    /* background-attachment: fixed; */
    background-repeat: no-repeat;
    background-position: 100% 50%;
    /* background-size: 50%; */
    width: 90%;
    height: 90%;



    border-radius: 30px;
    border-top: 6px solid #FF007A;
    border-bottom: 6px solid #FF007A;
    border-left: 6px solid #BDFF00;
    border-right: 6px solid #00EDFF;

    aspect-ratio: 1/1;

}

#curriculum-a-image {
    background-image: url('./images/ajay0004_a_set_of_stacked_books_about_war_strategy__hacking_fut_31d2baee-ed65-4b6b-b83d-4398c86a15f8.jpg');

}

#curriculum-b-image {
    background-image: url('./images/ajay0004_many_shiny_gold_trophies_won_for_hacking_on_a_podium_f_2a7b3655-8782-4ad8-8410-db7f57f0ad94.jpg');

}


#curriculums-section {
    max-width: 1200px;
    flex: 1;
    gap: 30px;
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    margin-left: 30px;
    margin-right: 30px;
}

.vertical-flex {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.vertical-flex .text-3d {
    width: 100% !important;
    text-align: center;
}

.vertical-flex p {
    width: 100% !important;
    text-align: center;
}

#curriculums-section .text-3d {
    font-size: 45px !important;
}

@media screen and (max-width: 900px) {

    #curriculums-section {
        margin: 0;
        width: 100%;
        /* display: ; */
        flex-direction: column;

    }

    #programs-heading-section {
        font-size: 14px;
    }


    #curriculums-section:nth-child(1) {
        flex-direction: column-reverse;
    }

    .image-holder {
        margin: 0;
        width: 100% !important;
        aspect-ratio: 0;

    }

    .vertical-flex p {
        width: 90% !important;
    }

    .curriculum-image {
        border-radius: 0;
        aspect-ratio: 0;
        width: 100%;
        height: 400px;
        border: 0;
        background-position: 50%;
        /* background-size: 80%; */
        /* background-attachment: fixed; */
    }

    #programs-heading-section .glowing-btn {
        display: none;
    }
}