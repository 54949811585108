@import url('https://fonts.googleapis.com/css2?family=Alegreya+Sans+SC:wght@500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fira+Mono:wght@300;400;500;700;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Monomaniac+One&display=swap');

/* Hide scrollbar for Chrome, Safari and Opera */
html::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
html {
  -ms-overflow-style: none;
  overscroll-behavior-y: contain;

  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
  margin: 0;
  width: 100vw;
  overflow-x: hidden;
  background-color: #11161C;

}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.description {
  margin-right: 30px;
  margin-left: 30px;
  color: #FFF;
  font-family: "Fira Code";
  font-size: 1.1em;
  line-height: 28px;
  font-style: normal;
  font-weight: 400;
  /* 42.6px */
}

.description span {
  color: #ff007b;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 700;
}

.description .s2 {
  color: #7DF5FE;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 700;
}

.description .s3 {
  color: #BDFF00;
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 700;
}

.text-3d {
  position: relative;
  z-index: 200;
}