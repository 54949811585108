.glitch {
    font-size: 5rem;
    font-weight: bold;
    text-transform: uppercase;
    position: relative;
    text-shadow: 0.05em 0 0 #7DF5FE, -0.03em -0.04em 0 #FF007A,
        0.025em 0.04em 0 #BDFF00;
    animation: glitch 725ms infinite;
}


.glitch span {
    position: absolute;
    top: 0;
    left: 0;
}

.glitch span:first-child {
    animation: glitch 500ms infinite;
    clip-path: polygon(0 0, 100% 0, 100% 35%, 0 35%);
    transform: translate(-0.04em, -0.03em);
    opacity: 0.75;
}

.glitch span:last-child {
    animation: glitch 375ms infinite;
    clip-path: polygon(0 65%, 100% 65%, 100% 100%, 0 100%);
    transform: translate(0.04em, 0.03em);
    opacity: 0.75;
}

@keyframes glitch {
    0% {
        text-shadow: 0.05em 0 0 #7DF5FE, -0.03em -0.04em 0 #FF007A,
            0.025em 0.04em 0 #BDFF00;
    }

    15% {
        text-shadow: 0.05em 0 0 #7DF5FE, -0.03em -0.04em 0 #FF007A,
            0.025em 0.04em 0 #BDFF00;
    }

    16% {
        text-shadow: -0.05em -0.025em 0 #7DF5FE, 0.025em 0.035em 0 #FF007A,
            -0.05em -0.05em 0 #BDFF00;
    }

    49% {
        text-shadow: -0.05em -0.025em 0 #7DF5FE, 0.025em 0.035em 0 #FF007A,
            -0.05em -0.05em 0 #BDFF00;
    }

    50% {
        text-shadow: 0.05em 0.035em 0 #7DF5FE, 0.03em 0 0 #FF007A,
            0 -0.04em 0 #BDFF00;
    }

    99% {
        text-shadow: 0.05em 0.035em 0 #7DF5FE, 0.03em 0 0 #FF007A,
            0 -0.04em 0 #BDFF00;
    }

    100% {
        text-shadow: -0.05em 0 0 #7DF5FE, -0.025em -0.04em 0 #FF007A,
            -0.04em -0.025em 0 #BDFF00;
    }
}