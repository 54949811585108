.battle-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(241px*0.8);
    height: calc(67.434px*0.8);
    flex-shrink: 0;
    background-image: url('./BattleButton.svg');
    background-size: contain;
    background-repeat: no-repeat;
    color: #FFF;
    text-align: center;
    font-family: "Alegreya Sans SC";
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    transform: scale(100%);
    line-height: calc(67.434px*0.8);
    transition-duration: 80ms;
    cursor: pointer;
    position: relative;
    z-index: 200;
}

#threejs-section .description {
    line-height: 23px;
    color: #e0e0e0;
    max-width: 700px;
}

#threejs-section .heading {
    margin-bottom: 10px;
}

.battle-button p {
    width: 100%;
    margin-right: 20px;
}

.battle-button:hover {
    transform: scale(120%);
}

/* SOCIALS */

.socials-bar {
    position: relative;
    z-index: 200;
    width: 120px;
    height: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    margin-bottom: 20px;
    margin-right: 20px;
}

.logo-social {
    width: 30px;
    cursor: pointer;
    height: 30px;
    color: #31aacc;
    background-size: contain;
    background-repeat: no-repeat;
    -webkit-filter: sepia(100%) hue-rotate(90deg) saturate(400%);
    filter: sepia(100%) hue-rotate(170deg) saturate(400%);
}

.logo-social:hover {
    transform: scale(110%);
}

#insta {
    background-image: url('./insta.png');
}

#discord {
    background-image: url(./discord.png);
    /* filter: sepia(100%) hue-rotate(90deg) saturate(400%); */
}

#youtube {
    background-image: url('./yt.png');
    /* filter: sepia(100%) hue-rotate(135deg) saturate(400%); */
}