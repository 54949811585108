.backdrop {
    width: 100%;
    height: auto;
    /* color: #111418; */
    background: linear-gradient(180deg, #11161C 16.6%, #151E25 85.74%), linear-gradient(180deg, #11161C 16.6%, #151E25 85.74%);
}



#main-heading-text {
    text-align: center;
    position: absolute;
    margin-top: 50px;
    top: 342px;
    left: 65%;
    transform: translate(-50%, -50%);
}

#main-heading-hero-picture {
    position: absolute;
    margin-top: 50px;
    top: 342px;
    left: 40%;
    transform: translate(-50%, -50%);
    width: 684px;
    height: 684px;
    background-color: red;
    /* background-image: url('./images/cyborg.png'); */
}

@media screen and (max-width: 599px) {
    #main-heading-text {
        top: 50%;
        left: 50%;

    }
}



.heading-blue {
    color: #7DF5FE;

    font-size: 110px;
    font-family: "Alegreya Sans SC";
    font-weight: 800;
    line-height: 105.6%;
    word-wrap: break-word;
}

.heading-white {
    color: #FFF;
    font-family: "Alegreya Sans SC";
    font-size: 110px;
    font-style: normal;
    font-weight: 800;
    line-height: 105.6%;
}

.backdrop-code {

    overflow: hidden;
    text-overflow: clip;
    pointer-events: none;
    position: fixed;
    margin: 0px;
    text-align: center;
    font-family: "Fira Code";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 177%;
    max-height: 100vh;
    z-index: 1;
    /* 40.71px */
    letter-spacing: 0.23px;
    background: linear-gradient(180deg, rgba(74, 97, 116, 0.1) 0%, rgba(211, 235, 255, .01) 120%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

/* Landing Page Main */

#landing-page-main {
    text-align: center;
    width: 100%;
    height: 100%;
    /* background-color: green; */
    display: flex;
    flex-direction: column;
}


#learn-more-section * {
    z-index: 200;
}

#contact-section * {
    z-index: 200;
}

#threejs-section * {
    z-index: 201;
}

.backblack {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    z-index: 201;
    background-color: rgba(25, 25, 31, 0.7);
}

#low-res-back {
    position: absolute;
    z-index: 0;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100vw;
    background-image: url('./images/hacker-battle-2x.jpg');

    background-size: cover;
    background-position: 50%;
    background-attachment: fixed;
    flex-direction: column;
}

#threejs-section {
    /* max-height: 75vh; */
    /* z-index: 1; */
    height: 100vh;
    display: flex;
    justify-content: center;
    background-image: url('./images/hacker-battle-low-res.jpg');

    align-items: center;
    background-size: cover;
    background-position: 50%;
    background-attachment: fixed;
    flex-direction: column;
}

#main-heading-cta {
    flex: 1;
    max-height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

}

#cta-heading {
    z-index: 101;
    width: 90%;
    height: 100px;
    /* background-image: url('./images/code-to-victory.svg'); */
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;

}

/* ABOUT REGION */

#about-section {
    margin-top: 50px;
    min-height: 512px;
    padding-bottom: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* border-bottom: 7px dotted #FFF; */
    /* background: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(152, 205, 0, 0.10) 100%); */
}


#about-main-pic {
    max-width: 600px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex: 1;
}

#about-text {
    flex: 1;
    max-width: 600px;
}


@media screen and (max-width: 850px) {
    #about-section {
        flex-direction: column;
        max-height: 1000px;
        min-height: 800px;
    }

    #about-main-pic {
        max-width: none;
    }

    #about-text {
        max-width: none;
    }

}


#about-pic {
    position: relative;

    width: 100%;
    height: 100%;

    background-image: url('./images/about-picture.png');
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;

    max-width: 413px;
    max-height: 512px;

    min-height: 350px;
    margin-bottom: 20px;
}

/* FEATURES */


#features-section {
    display: flex;
    background-color: #10141a;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    padding-top: 50px;
    margin-bottom: 30px;
}

#features-section .text-3d {
    margin-bottom: 30px;
}

#features-grid {
    margin-bottom: 50px;
    max-width: 1150px;

    width: 80%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* Two columns */
    grid-template-rows: 1fr 1fr;
    /* Two rows */
    gap: 50px;
    justify-items: center;
    /* Space between the squares */
}




.grid-item {

    max-width: 45vw;
    cursor: crosshair;
    background-color: #151E25;
    /* Light grey background for visibility */
    display: flex;
    justify-content: center;
    flex-direction: column;
    /* Center content horizontally */
    align-items: center;
    /* Center content vertically */
    border: 3px solid #ccc;
    /* Border for visibility */
    /* To ensure squares */
    border-radius: 23px;

    box-shadow: inset 0 0 0 2000px rgba(31, 31, 31, 0.5);
    background-size: cover;
    background-repeat: no-repeat;
    aspect-ratio: 1/1;
    background-position: 50%;
}

@media screen and (max-width: 850px) {
    #features-grid {
        display: flex;
        width: 100vw;
        height: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0px;
    }

    .grid-item:nth-child(1) {
        border-top: 2px solid rgba(255, 255, 255, 0.334);
        ;
    }

    .grid-item {
        box-shadow: inset 0 0 0 2000px rgba(31, 31, 31, 0.3);
        max-width: 100vw;
        flex: 1;
        aspect-ratio: 0;
        margin: 0;
        min-height: 400px;
        border-radius: 0;
        border-bottom: 2px;
        border-color: rgba(255, 255, 255, 0.334);
        border-style: solid;
        /* border-bottom: 2; */
        margin-top: 0;
        border-top: 0;
        border-left: 0;
        border-right: 0;
    }
}

.grid-item p {
    height: 0px;
    opacity: 0%;
    color: white;
    font-family: 'Fira Code';
}

.grid-item:hover p {
    height: auto;
    opacity: 100%;
    margin: 20px;
}

.grid-item:hover h1 {
    font-size: 0px;
    -webkit-text-stroke-width: 0px;
    -webkit-text-stroke-color: transparent;
}

.grid-item:hover {
    transition-duration: 300ms;

    box-shadow: inset 0 0 0 2000px rgba(31, 31, 31, 0.9);
}

.grid-item h1 {

    -webkit-text-stroke-width: 2px;
    -webkit-text-stroke-color: #FFF;
    font-family: "Alegreya Sans SC";
    font-size: 50px;
    font-style: normal;
    font-weight: 800;
    color: transparent;
    text-wrap: wrap;
    margin: 20px;
    /* 68.64px */
}

.grid-item:nth-child(1) {
    background-image: url('./images/ajay0004_a_deck_of_cards_each_with_a_different_fantasy_soldier__ccd49e09-0054-4210-a5a5-55bdc68d5d8c.jpg');
}

.grid-item:nth-child(2) {
    background-image: url('./images/realtime-strategy.jpg');
}

.grid-item:nth-child(3) {
    background-image: url('./images/ajay0004_code_editor_hacker_theme_fantasy_battle_toon_801e324f-e6d8-4666-9449-bb5d73716037\(1\).jpg');
}

.grid-item:nth-child(4) {
    background-image: url('./images/ajay0004_two_students_holding_computers_enthusiastic_and_compet_120b9e1d-033d-48d8-b37a-32ae7906fc14.jpg');
}

/* LEARN MORE SECTION */

.slanted-border {
    width: 100vw;
    overflow: clip;
    margin-bottom: 20px;
}

#learn-more-section {
    height: auto;
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    justify-content: center;
    align-items: center;
}

.half-flex {

    display: flex;
    height: auto;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 450px;
    max-width: 1200px;
}

.half-flex .image-div {
    flex: 1;
    display: flex;
    width: 50%;
    justify-content: center;
    height: 100%;
    align-items: center;
    text-wrap: wrap;


}

.half-flex div {
    flex: 1;
}

#combat-strategy-video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 30px;

}

#tutorial-image {
    border-radius: 30px;
    border-top: 6px solid #FF007A;
    border-bottom: 6px solid #FF007A;
    border-left: 6px solid #BDFF00;
    border-right: 6px solid #00EDFF;
    background-image: url('./images/ajay0004_school_student_extremely_engaged_playing_a_game_on_the_c410c420-da09-4ed9-a457-fa5d56f6ebc1.jpg');
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    margin-right: 30px;
    aspect-ratio: 1/1;
    max-width: 500px;
    max-height: 600px;
}

#tutorial-image-2 {
    border-radius: 30px;
    border-top: 6px solid #FF007A;
    border-bottom: 6px solid #FF007A;
    border-left: 6px solid #BDFF00;
    border-right: 6px solid #00EDFF;
    background-image: url('./images/ajay0004_a_teacher_excitedly_teaching_battle_strategy_to_very_e_ee8562bb-c52c-4399-8a03-b0ea54b772e4.jpg');
    background-size: cover;
    background-position: 50%;
    background-repeat: no-repeat;
    margin-left: 30px;
    max-width: 500px;
    max-height: 600px;

}

#contact-section .description {
    max-width: 50vw;
}



@media screen and (max-width: 900px) {

    .half-flex .text-3d {
        margin-top: 20px;
    }

    #half-flex-2 {
        display: flex;
        flex-direction: column;
        width: auto;
        height: auto;
    }

    #half-flex-1 {
        display: flex;
        /* flex-direction: column; */
        width: auto;
        flex-direction: column-reverse;

        height: auto;
    }

    .half-flex div {
        flex: none;
    }


    #tutorial-image {
        position: relative;
        /* aspect-ratio: 0; */
        /* flex: 1; */
        /* aspect-ratio: 1/1; */
        margin: 0px;
        /* height: 100%; */
        /* height: 300px; */
        /* max-width: 400px; */
        border: 0;
        min-height: 400px;
        border-radius: 0;
        width: 100vw;
        max-width: 100vw;
        aspect-ratio: 0;
        max-height: 100vh;

    }

    #tutorial-image-2 {
        position: relative;
        /* aspect-ratio: 0; */
        /* flex: 1; */
        /* aspect-ratio: 1/1; */
        margin: 0px;
        /* height: 100%; */
        /* height: 300px; */
        /* max-width: 400px; */
        border: 0;
        min-height: 400px;
        border-radius: 0;
        width: 100vw;
        max-width: 100vw;
        aspect-ratio: 0;
        max-height: 100vh;
        background-position-y: 35%;
        /* background- */
    }


    #contact-section .description {
        max-width: 80vw;
    }


}

#contact-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 40px;
}

#contact-section form {
    display: flex;
    flex-direction: column;
    width: 100%;

}

#contact-section p {
    text-align: center;
}

#contact-section h1 {
    color: #FFF;
    text-align: center;
    font-family: "Monomaniac One";
    font-size: 40px;
    font-style: normal;
    font-weight: 400;
    line-height: 105.6%;
    /* 52.8px */

}



form {
    max-width: 420px;
    /* margin: 50px auto; */
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 20px;
}

.feedback-input {
    color: white;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: 500;
    font-size: 18px;
    border-radius: 5px;
    line-height: 22px;
    background-color: transparent;
    border: 2px solid #BDFF00;
    transition: all 0.3s;
    padding: 13px;
    margin-bottom: 15px;
    width: 100%;
    box-sizing: border-box;
    outline: 0;

    background-color: #0E1419;
}

.feedback-input:focus {
    border: 2px solid #CC4949;
}

textarea {
    height: 150px;
    line-height: 150%;
    resize: vertical;
}

[type="submit"] {
    font-family: 'Montserrat', Arial, Helvetica, sans-serif;
    width: 100%;
    background: #ff007b;
    border-radius: 5px;
    /* border: 5px; */
    cursor: pointer;
    color: white;
    font-size: 24px;
    padding-top: 10px;
    padding-bottom: 10px;
    transition: all 0.3s;
    margin-top: -4px;
    font-weight: 700;
    border-color: white;
    border-width: 0px;
    border-style: solid;
}

[type="submit"]:hover {
    background: #da4f92;
}

.footer {
    /* flex: 1; */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    overflow: clip;

    /* max-height: 100px; */
    /* background-color: beige; */
}


.footer p {
    color: #3B566A;
    text-align: center;
    font-family: "Monomaniac One";
    font-size: 15;
    font-style: normal;
    font-weight: 400;
    min-width: 200px;
    /* 24.288px */
    text-decoration-line: underline;
}

@media screen and (max-width: 900px) {
    .footer {
        flex-direction: column;
        justify-content: flex-start;
        margin-bottom: 20px;
    }

    .footer p {
        margin: 0;
    }

}