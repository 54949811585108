.glitch-mini-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    /* background-color: #000000; */
}

.glitch-mini {
    position: relative;
    font-size: 30px;
    font-weight: 400;
    font-family: 'Monomaniac One';
    color: #FFFFFF;
    letter-spacing: 3px;
    z-index: 1;
}

.glitch-mini:before,
.glitch-mini:after {
    display: block;
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0.8;
}

.glitch-mini:before {
    animation: glitch-it 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both infinite;
    color: #00FFFF;
    z-index: -1;
}

.glitch-mini:after {
    animation: glitch-it 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) reverse both infinite;
    color: #FF00FF;
    z-index: -2;
}

@keyframes glitch-it {
    0% {
        transform: translate(0);
    }

    20% {
        transform: translate(-2px, 2px);
    }

    40% {
        transform: translate(-2px, -2px);
    }

    60% {
        transform: translate(2px, 2px);
    }

    80% {
        transform: translate(2px, -2px);
    }

    to {
        transform: translate(0);
    }
}