.header {
    transition-duration: 300ms;
    height: 50px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0px;
    width: 100vw;
    z-index: 400;
    position: fixed;
    /* background-color: #11161ce2; */
}

.ham-header {
    display: none;

    position: fixed;
    top: 0px;
    width: 100vw;
    justify-content: space-between;
    align-items: center;
    z-index: 400;

}

.header p {
    cursor: pointer;
    transition-duration: 200ms;
    color: #e8feff;
    text-align: center;
    font-family: "Fira Code";
    font-size: 16px;

    font-style: normal;
    /* 16.896px */
}

.header p:hover {
    font-weight: bold;
}

.header .sub-header-section {
    display: flex;
    flex: 1;
    flex-direction: row;
    padding-right: 20px;
    padding-left: 20px;
    text-wrap: nowrap;
    justify-content: space-between;

}

.sub-header-section p {
    padding-left: 50px;
    padding-right: 50px;

}

@media screen and (max-width: 1000px) {
    .sub-header-section p {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media screen and (max-width: 720px) {
    .header {
        display: none;
    }

    .ham-header {
        display: flex;
    }


}

.ham-open-list {
    display: flex;
    flex-direction: column;
    height: auto;
    color: white;
    margin-top: 70px;
    text-align: center;
    width: 100%;
}

.ham-open-list div {
    height: 80px;
    transition-duration: 300ms;

    width: 100%;
    line-height: 80px;
    font-family: 'Fira Code';
    cursor: pointer;
}

.ham-open-list div:hover {
    font-size: 20px;

    opacity: 1;
}

.ham-open-list div:nth-child(odd) {
    background-color: #354755;
}

.ham-open-list div:nth-child(odd):hover {
    background-color: #3b89c5;
}


.ham-open-list div:nth-child(even):hover {
    background-color: #3b89c5;
}

.ham-open-list div:nth-child(even) {
    background-color: #283844;
}


.logo {

    transition-duration: 300ms;
    min-width: 60px;
    min-height: 60px;
    background-image: url('./Logo.png');
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.logo:hover {

    transform: rotate(360deg);
}

.hamburger-open {
    min-width: 40px;
    min-height: 40px;
    background-image: url('./HamburgetMenuIcon.png');
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
}

.hamburger-close {
    min-width: 30px;
    min-height: 30px;
    background-image: url('./HamburgerMenuClose.png');
    background-size: contain;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-right: 10px;
}


/* Heading */
.heading {
    color: #FFF;
    text-align: center;
    font-family: 'Fira Mono';
    font-size: 100px;
    font-style: normal;
    font-weight: 700;
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

.ham-open-header {
    z-index: 400;
    position: fixed;
    width: 100vw;
    height: 100vh;
    opacity: 0.97;
    background: linear-gradient(180deg, #11161C 16.6%, #151E25 85.74%), linear-gradient(180deg, #11161C 16.6%, #151E25 85.74%);
}